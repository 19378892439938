import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {Button, ButtonGroup, Col, Row, Table} from "react-bootstrap";
import pocketbaseContext from "./pocketbase";
import {forEach} from "react-bootstrap/ElementChildren";

interface Item {
    id: number;
    collectionId: string;
    name: string;
    price: number;
    deposit: number;
    image: string;
    color?: string;
    isClicked?: boolean;
    gridposition: number;
    show: boolean;
}

interface CartItem {
    item: Item;
    quantity: number;
}

const Dashboard: FC = () => {
    const [items, setItems] = useState<Item[]>([])
    const [cart, setCart] = useState<CartItem[]>([])
    const [actions, setActions] = useState<CartItem[][]>([])
    const refs = useRef([React.createRef(), React.createRef()]);
    const [lastCart, setLastCart] = useState<CartItem[]>([])
    const [showLastCart, setShowLastCart] = useState<boolean>(false)
    const pb = useContext(pocketbaseContext);

    useEffect(() => {
        pb.collection('items').getFullList().then((items: any) => {
            console.log(items);
            items = items.filter((item: any) => item.show);
            items = items.sort((a: any, b: any) => a.gridposition - b.gridposition);
            setItems(items);
        });
    }, []);

    const addToCart = (item: Item) => {
        const itemInCart = cart.find(cartItem => cartItem.item.id === item.id)
        if (itemInCart) {
            setCart(cart => cart.map(cartItem => {
                if (cartItem.item.id === item.id) {
                    return {
                        ...cartItem,
                        quantity: cartItem.quantity + 1
                    }
                } else {
                    return cartItem
                }
            }))
        } else {
            setCart(cart => [...cart, {
                item,
                quantity: 1
            }])
        }
        setActions(actions => [...actions, cart])
    }

    const clearCart = () => {
        setLastCart(cart)
        setCart([])
        setActions([])
    }

    const createOrder = (method: string) => {
        console.log(pb.authStore.model);
        debugger
        if (pb.authStore?.model === null) {
            throw new Error('User model should not be null');
        }

        pb.collection('orders').create({
            user: pb.authStore.model.id,
            method
        }).then((order) => {
            return Promise.all(
                cart.map(async (cartItem) => {
                    return pb.collection('order_items').create({
                        order: order.id,
                        item: cartItem.item.id,
                        quantity: cartItem.quantity
                    });
                }));
        }).then(() => {
            clearCart();
        }).catch(console.error);
    }

    const handleClick = (clickedItem: Item) => {
        addToCart(clickedItem)
        const updatedItems = items.map(item => ({
            ...item,
            isClicked: item.id === clickedItem.id,
        }));
        setItems(updatedItems);
        setTimeout(() => {
            setItems(items.map(item => ({...item, isClicked: false})));
        }, 100);

    };

    const undo = () => {
        const lastAction = actions.pop()
        if (lastAction) {
            setCart(cart => lastAction)
        }
    }


    return (
        <div className="dashboard-container">
            <div className='overview'>
                <Table striped bordered responsive>
                    <thead>
                    <tr>
                        <th>Position</th>
                        <th>Anz</th>
                        <th>Pfand</th>
                        <th>Preis</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        showLastCart ? lastCart.map(item => {
                                return <tr>
                                    <td>{item.item.name.slice(0, 9)}</td>
                                    <td>{item.quantity}</td>
                                    <td>{(item.item.deposit * item.quantity).toFixed(2) + " €"}</td>
                                    <td>{(item.item.price * item.quantity).toFixed(2) + " €"}</td>
                                </tr>
                            }) :
                            cart.map(item => {
                                return <tr>
                                    <td>{item.item.name.slice(0, 9)}</td>
                                    <td>{item.quantity}</td>
                                    <td>{(item.item.deposit * item.quantity).toFixed(2) + " €"}</td>
                                    <td>{(item.item.price * item.quantity).toFixed(2) + " €"}</td>
                                </tr>
                            })
                    }
                    </tbody>
                </Table>
            </div>
            <div className='controls'>
                <Button className="w-100" disabled={lastCart.length < 1} variant={lastCart.length < 1 ? "outline-light" : "light"}
                        onClick={() => setShowLastCart(!showLastCart)}>
                    {!showLastCart ? "Letzte Bestellung" : "Zurück"}
                </Button>
                {
                    // <ButtonGroup className="w-100">
                    //     <Button variant={`${actions.length === 0 ? 'secondary-outline' : 'warning'}`}
                    //             disabled={actions.length === 0}
                    //             onClick={undo}>Rückgängig</Button>
                    // </ButtonGroup>
                }
                <h1>Total: {cart.reduce((acc, item) => acc + (item.item.price * item.quantity) + (item.item.deposit * item.quantity), 0).toFixed(2) + " €"}</h1>
                <Row className="cart-buttons p-0">
                    <Col className="p-0"><Button variant="danger" size='lg' className='w-100 h-100'
                                                 disabled={cart.length < 1}
                                                 onClick={() => createOrder('clear')}>Alles Entfernen</Button></Col>
                    <Col className="p-0">
                        <Button className="w-100" variant="info" size='lg' disabled={cart.length < 1}
                                onClick={() => createOrder('ec')}>EC</Button>
                        <Button className="w-100" style={{height: "5rem"}} variant="success" size='lg'
                                disabled={cart.length < 1}
                                onClick={() => createOrder('normal')}>Bar</Button>
                    </Col>
                </Row>
            </div>
            <div className='items'>
                <div className='item-grid'>
                    {
                        items.map(item => {
                            return <div className="item-grid-item" key={item.id}>

                                <div className={`item ${item.isClicked ? 'clicked' : ''}`}
                                     style={{backgroundColor: item.color}} onClick={() => {
                                    handleClick(item)
                                }}>
                                    {
                                        item.image !== "" ? <img
                                            src={`https://pocketbase.cambaru.de/api/files/${item.collectionId}/${item.id}/${item.image}`}
                                            alt={"No image"}/> : null
                                    }
                                    <h4>{item.name}</h4>
                                    <h3>{item.price.toFixed(2) + " €"}</h3>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className='logout-button'>
                <Button variant="outline-light" size='sm' onClick={() => {
                    pb.authStore.clear();
                    window.location.reload()
                }}
                >Logout</Button>
            </div>
        </div>
    )
}

export default Dashboard;