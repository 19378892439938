import React, {FC, useState} from 'react';
import {ButtonGroup} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateSelector: FC<{ handler: [Date, React.Dispatch<React.SetStateAction<Date>>] }> = ({handler}) => {
    const [selectedDate, setSelectedDate] = handler

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
    };

    const handlePrevDay = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(selectedDate.getDate() - 1);
        setSelectedDate(newDate);
    };

    const handleNextDay = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(selectedDate.getDate() + 1);
        setSelectedDate(newDate);
    };

    return (
        <div className="d-flex">
            <button className="btn btn-dark" onClick={handlePrevDay}>&lt;</button>
            <DatePicker selected={selectedDate} onChange={handleDateChange} dateFormat="dd.MM.yyyy"/>
            <button className="btn btn-dark" onClick={handleNextDay}>&gt;</button>
        </div>
    );
};

export default DateSelector;
