import {Alert, Button, ButtonGroup, Form} from "react-bootstrap";
import {createContext, useContext, useEffect, useState} from "react";
import Dashboard from "./dashboard";
import pocketbaseContext from "./pocketbase";
import Index from "./admindashboard";


const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
    const pb = useContext(pocketbaseContext);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        console.log(loggedIn)
        setLoggedIn(pb.authStore.isValid);
        console.log(loggedIn)
    }, []);

    const login = async () => {
        try {
            const authData = await pb.collection('users').authWithPassword(username, password);
        } catch (e) {
            setError(true);
        }
        setLoggedIn(pb.authStore.isValid);
    }

    const managerLogin = async () => {
        const authData = await pb.admins.authWithPassword(username, password);
        setLoggedIn(pb.authStore.isValid);
    }

    const submit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
    };

    return <>
    {
        loggedIn ?
            (pb.authStore.isAdmin ?
                <Index/> :
                <Dashboard/>) :
            <div className='login'>
                <div className='login-background'>
                    <Alert show={error} variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>Fehler</Alert.Heading>
                        <p>
                            Falscher Benutzername oder Passwort
                        </p>
                    </Alert>
                    <h1>Weihnachtszauber</h1>
                    <Form onSubmit={submit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>TabletNr</Form.Label>
                            <Form.Control type="text" placeholder="TabletX" value={username}
                                          onChange={(e) => setUsername(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Passwort</Form.Label>
                            <Form.Control type="text" placeholder="12345" value={password}
                                          onChange={(e) => setPassword(e.target.value)}/>
                        </Form.Group>
                        <ButtonGroup className={"w-100 mt-5"}>
                            <Button variant="success" onClick={login}>
                                Login
                            </Button>
                            <Button variant="outline-dark"  onClick={managerLogin}>
                                Manager
                            </Button>
                        </ButtonGroup>
                    </Form>

                </div>
            </div>
    }
    </>
}

export default Login;