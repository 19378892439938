import React, {FC, useState} from 'react';
import { Form } from 'react-bootstrap';

const MethodSelector:FC<{methods:  [string[], React.Dispatch<React.SetStateAction<string[]>>]}> = ({ methods }) => {
    const [selectedMethods, setSelectedMethods] = methods;
    const [availableMethods, setAvailableMethods] = useState<string[]>(['ec', 'normal', 'clear']);
    const handleCheckboxChange = (method: string) => {
        if (selectedMethods.includes(method)) {
            setSelectedMethods(selectedMethods.filter((selectedMethod) => selectedMethod !== method));
        } else {
            setSelectedMethods([...selectedMethods, method]);
        }
    };

    return (
        <Form>
            {availableMethods.map((method) => (
                <Form.Check
                    key={method}
                    type="checkbox"
                    id={method}
                    label={method}
                    checked={selectedMethods.includes(method)}
                    onChange={() => handleCheckboxChange(method)}
                />
            ))}
        </Form>
    );
};

export default MethodSelector;
