import React from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from "./components/dashboard";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from "react-bootstrap";
import Login from "./components/login";

function App() {
    return (
        <div className="App">
                <Login />
        </div>
    );
}

export default App;
