import {
    Accordion,
    Button, Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Row,
    Table
} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import pocketbaseContext from "../pocketbase";
import DateSelector from "./dateSelector";
import MethodSelector from "./methodSelector";


interface User {
    id: number;
    username: string;
    orders: Order[];
}

interface Order {
    id: number;
    method: string;
    created_at: Date;
    updated_at: Date;
    order_items: OrderItem[];
}

interface OrderItem {
    id: number;
    item_id: number;
    quantity: number;
    item: Item;
}

interface Item {
    id: number;
    name: string;
    price: number;
    deposit: number;
    image: string;
    show: boolean;
}

const isToday = (someDate: Date): boolean => {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );
}

const Index = () => {
    const pb = useContext(pocketbaseContext);
    const [users, setUsers] = useState<User[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [selectedMethods, setSelectedMethods] = useState<string[]>(['ec', 'normal']);


    useEffect(() => {
        const getData = async () => {
            const [allOrders, allItems, allUsers, allOrderItems] = await Promise.all([
                pb.collection('orders').getFullList(),
                pb.collection('items').getFullList(),
                pb.collection('users').getFullList(),
                pb.collection('order_items').getFullList()
            ])
            return [allOrders, allItems, allUsers, allOrderItems]
        }
        getData().then(([allOrders, allItems, allUsers, allOrderItems]) => {
            const data = allUsers.map((user: any) => {
                const userOrders = allOrders.filter((order: any) => order.user === user.id);
                return {
                    id: user.id,
                    username: user.username,
                    orders: userOrders.map((order: any) => {
                        const orderItems = allOrderItems.filter((orderItem: any) => orderItem.order === order.id);
                        return {
                            id: order.id,
                            method: order.method,
                            created_at: new Date(Date.parse(order.created)),
                            updated_at: new Date(Date.parse(order.updated)),
                            order_items: orderItems.map((orderItem: any) => {
                                const item = allItems.find((item: any) => item.id === orderItem.item);
                                return {
                                    id: orderItem.id,
                                    item_id: orderItem.item,
                                    quantity: orderItem.quantity,
                                    item: item ? {
                                        id: item.id,
                                        name: item.name,
                                        price: item.price,
                                        deposit: item.deposit,
                                        image: item.image,
                                        show: item.show
                                    } : {}
                                }
                            })
                        }
                    })
                }
            })
            console.log(data);
            setUsers(data as any as User[]);
        })
    }, []);

    const groupOrdersByDate = (user: User): Record<string, Order[]> => {
        const ordersByDate: Record<string, Order[]> = {};

        user.orders.forEach((order) => {
            // Skip orders with methods that are not allowed
            if (!selectedMethods.includes(order.method)) {
                // Extract the date in YYYY-MM-DD format
                const dateKey = order.created_at.toISOString().split('T')[0];

                // If the dateKey doesn't exist in the dictionary, create an empty array
                if (!ordersByDate[dateKey]) {
                    ordersByDate[dateKey] = [];
                }

                // Push the order to the array corresponding to the date
                ordersByDate[dateKey].push(order);
            }
        });
        return ordersByDate;
    }

    const groupItemsByOrders = (orders: Order[]): (Item & { quantity: number })[] => {
        const items: Record<string, Item & { quantity: number }> = {};

        orders.forEach((order) => {
            order.order_items.forEach((orderItem) => {
                const itemName = orderItem.item.name;

                if (!items[itemName]) {
                    // Create a new object that combines properties of Item and quantity
                    items[itemName] = {...orderItem.item, quantity: 0};
                }

                // Increment the quantity
                items[itemName].quantity += orderItem.quantity;
            });
        });

        // Convert the dictionary into an array of items
        const result: (Item & { quantity: number })[] = Object.values(items);

        return result;
    };


    const selectedUserOrdersOnDate = (): Order[] => {
        if (!selectedUser) return [];

        const orders = selectedUser.orders.filter((order) => {
            return isSameDate(order.created_at, date) && selectedMethods.includes(order.method);
        });

        return orders;
    }

    const itemsSoldOnDate = (user: User, targetDate: Date): Record<string, number> => {
        const itemsSold: Record<string, number> = {};

        user.orders.forEach((order) => {
            // Extract the date in YYYY-MM-DD format
            const orderDate = order.created_at.toISOString().split('T')[0];

            if (orderDate === targetDate.toISOString().split('T')[0]) {
                order.order_items.forEach((orderItem) => {
                    const itemName = orderItem.item.name;

                    // If the item name doesn't exist in the dictionary, initialize it to 0
                    if (!itemsSold[itemName]) {
                        itemsSold[itemName] = 0;
                    }

                    // Increment the count of sold items
                    itemsSold[itemName] += orderItem.quantity;
                });
            }
        });
        return itemsSold;
    }

    return (
        <Container fluid style={{backgroundColor: "white", minHeight: "100vh", minWidth: "100vw"}}>
            <h1>Admin Dashboard</h1>
            <div className='logout-button'>
                <Button variant="outline-light" size='sm' onClick={() => {
                    pb.authStore.clear();
                    window.location.reload()
                }}
                >Logout</Button>
            </div>
            <DateSelector handler={[date, setDate]}/>
            <DropdownButton title={selectedUser ? selectedUser.username : "Tablet auswahl"}>
                {
                    users.map((user: User, idx: number) => {
                        return <Dropdown.Item eventKey={idx}
                                              onClick={() => setSelectedUser(user)}>{user.username}</Dropdown.Item>
                    })
                }

            </DropdownButton>
            <MethodSelector methods={[selectedMethods, setSelectedMethods]}/>
            <h2>Tagesumsatz:</h2>
            <Table striped borderless>
                <thead>
                <tr>
                    <th>Position</th>
                    <th>Einzelpreis</th>
                    <th>Einzelpfand</th>
                    <th>Anzahl</th>
                    <th>Summe</th>
                </tr>
                </thead>
                <tbody>
                {selectedUser && groupItemsByOrders(selectedUserOrdersOnDate()).map((item) => {
                    return <tr>
                        <td>{item.name}</td>
                        <td>{item.price.toFixed(2)} €</td>
                        <td>{item.deposit.toFixed(2)} €</td>
                        <td>{item.quantity}</td>
                        <td className="text-end">{((item.price + item.deposit) * item.quantity).toFixed(2)} €</td>
                    </tr>
                })}
                <tr>
                    <td colSpan={4} className='bg-dark text-light'>Gesamt
                    </td>
                    <td className="text-end bg-dark text-light">
                        {selectedUser && groupItemsByOrders(selectedUserOrdersOnDate()).reduce((acc, item) => {
                            return acc + (item.price + item.deposit) * item.quantity
                        }, 0).toFixed(2)} €
                    </td>
                </tr>
                </tbody>
            </Table>
            <h2>Bestellungen ({selectedUserOrdersOnDate().length ?? "Bitte Tablet auswählen"}):</h2>
            <Row>
                {
                    selectedUser && selectedUserOrdersOnDate().map((order) => {
                        return <Col xs={12} md={4} lg={3} className='mb-3'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Bestellungseingang: {order.created_at.toLocaleString()}
                                        <br/>
                                        Methode: {order.method}
                                    </Accordion.Header>
                                    <Accordion.Body style={{overflow: "scroll"}}>
                                        {
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>Position</th>
                                                    <th>Einzelpreis</th>
                                                    <th>Einzelpfand</th>
                                                    <th>Anzahl</th>
                                                    <th>Summe</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {order.order_items.map((orderItem) => {
                                                    return <tr>
                                                        <td>{orderItem.item.name}</td>
                                                        <td>{orderItem.item.price.toFixed(2)} €</td>
                                                        <td>{orderItem.item.deposit.toFixed(2)} €</td>
                                                        <td>{orderItem.quantity}</td>
                                                        <td className="text-end">{((orderItem.item.price + orderItem.item.deposit) * orderItem.quantity).toFixed(2)} €</td>
                                                    </tr>
                                                })}
                                                <tr>
                                                    <td colSpan={4} className='bg-dark text-light'>Gesamt
                                                    </td>
                                                    <td className="text-end bg-dark text-light">
                                                        {order.order_items.reduce((acc, orderItem) => {
                                                            return acc + (orderItem.item.price + orderItem.item.deposit) * orderItem.quantity
                                                        }, 0).toFixed(2)} €
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    })
                }
            </Row>
        </Container>
    )
}

export default Index;
